@mixin jello-horizontal($yoffset: -50%) {
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1) translateY($yoffset);
      transform: scale3d(1, 1, 1) translateY($yoffset);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1) translateY($yoffset);
      transform: scale3d(1.25, 0.75, 1) translateY($yoffset);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1) translateY($yoffset);
      transform: scale3d(0.75, 1.25, 1) translateY($yoffset);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1) translateY($yoffset);
      transform: scale3d(1.15, 0.85, 1) translateY($yoffset);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1) translateY($yoffset);
      transform: scale3d(0.95, 1.05, 1) translateY($yoffset);
    }
    75% {
      -webkit-transform: scale3d(1, 1, 1) translateY($yoffset);
      transform: scale3d(1, 1, 1) translateY($yoffset);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1) translateY($yoffset);
      transform: scale3d(1, 1, 1) translateY($yoffset);
    }
  }
}
@mixin jello-vertical($yoffset: -50%) {
  @-webkit-keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%);
      transform: scale3d(1, 1, 1) translateY(-50%);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1) translateY(-50%);
      transform: scale3d(0.75, 1.25, 1) translateY(-50%);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1) translateY(-50%);
      transform: scale3d(1.25, 0.75, 1) translateY(-50%);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1) translateY(-50%);
      transform: scale3d(0.85, 1.15, 1) translateY(-50%);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1) translateY(-50%);
      transform: scale3d(1.05, 0.95, 1) translateY(-50%);
    }
    75% {
      -webkit-transform: scale3d(0.95, 1.05, 1) translateY(-50%);
      transform: scale3d(0.95, 1.05, 1) translateY(-50%);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%);
      transform: scale3d(1, 1, 1) translateY(-50%);
    }
  }
  @keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(0.75, 1.25, 1) translateY(-50%) rotate(90deg);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1.25, 0.75, 1) translateY(-50%) rotate(90deg);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(0.85, 1.15, 1) translateY(-50%) rotate(90deg);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1.05, 0.95, 1) translateY(-50%) rotate(90deg);
    }
    75% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
    }
  }
}
