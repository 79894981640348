@use '../colors';
@use '../responsive';
@use './modal';
* {
}
.contact-section {
  min-height: 100vh;

  padding-top: 0.1px;

  //naslov glavni
  h1 {
    margin-top: 100px;
    text-align: center;
    font-size: 4rem;
    color: colors.$yellow-main;
    letter-spacing: 2px;
    margin-bottom: 25px;
  }

  h3.contact-heading {
    text-align: center;
    font-size: 2rem;
    color: colors.$yellow-main;
  }

  .contact-content {
    //kontent
    display: flex;
    color: colors.$yellow-main;

    padding: 0px 100px;
    margin-bottom: 100px;
    //informacije levo
    .left-info-container {
      width: 50%;

      & > h3 {
        color: white;
      }
      //informacije sa leve strane
      .info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        //pojedinacna informacija
        .single-contact-info {
          width: 60%;

          margin-top: 30px;
          display: flex;
          align-items: center;
          //slicica
          .contact-img-wrapper {
            width: 20%;
            padding-top: 0.5%;
            line-height: 0px;
            img {
              width: 100%;
            }
          }
          //tekst sa desne strane
          .text-description {
            margin-left: 10%;
            h5 {
              font-size: 1.4rem;
            }
            p {
              color: white;
            }
          }
        }
      }
    }

    //kontakt forma desno
    .right-contact-form-container {
      width: 50%;

      form {
        margin-top: 15px;

        div.form-row {
          display: flex;
          //align-items: baseline;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;

          label {
            width: 100%;
            font-size: 1.4rem;
            padding-top: 10px;
          }
          input {
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid white;
            color: white;

            font-size: 1.4rem;

            &:focus,
            &:active {
              outline: none;
              border: none;
              border-bottom: 3px solid colors.$yellow-main;
            }
          }

          //poruka input
          textarea {
            width: 100%;
            height: 110px;
            border: 3px solid white;
            color: white;
            background-color: transparent;
            resize: none;

            padding: 10px;
            border-radius: 4px;
            font-size: 1.1rem;
            &:focus,
            active {
              outline: none;
              border: 4px solid colors.$yellow-main;
            }
          }
        }
        //submit button
        .submit-message-btn {
          margin: 0 auto;
          display: block;
          width: 40%;
          padding: 18px;
          font-size: 1.4rem;

          // background-color: colors.$yellow-main;
          // color: rgb(28, 25, 25);
          // border: none;
          // border-radius: 6px;

          cursor: pointer;
        }
      }
    }
  }
  .map-container {
    h3 {
      margin-bottom: 8px;
    }
    h3.adress-heading {
      color: colors.$yellow-main;
      margin-bottom: 20px;
    }
    #map-heading {
      color: white;

      strong {
        color: colors.$yellow-main;
        font-size: 2.2rem;
      }
    }
    iframe {
      width: 100%;
      height: 500px;
      //filter: invert(90%) hue-rotate(180deg);
    }
  }
}

//RESPONSIVNOST

@media screen and (max-width: responsive.$extralarge) {
  .contact-content {
    padding-left: 0px !important;
    padding-right: 30px !important;

    justify-content: center;
  }
}
@media screen and (max-width: responsive.$small) {
  .contact-section {
    //naslov glavni
    h1 {
    }
    .contact-content {
      flex-direction: column;
      align-items: center;
      padding: 0px 5%;
      padding-left: 5% !important;

      padding-right: 5% !important;
      //informacije levo
      .left-info-container {
        width: 100%;
        margin-bottom: 65px;
        .info-wrapper {
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-end;

          //pojedinacna
          .single-contact-info {
            width: 90%;

            .contact-img-wrapper {
              width: 18%;
              margin-right: 8%;
            }
            .text-description {
              h5 {
                font-size: 1.8rem;
              }
              p {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
      //desno stvari - kontakt forma
      .right-contact-form-container {
        width: 90%;

        .contact-heading {
          font-size: 3rem;
        }

        .form-row {
          label {
            font-size: 1.6rem !important;
          }
          textarea {
            font-size: 1.3rem !important;
          }
        }
        .submit-message-btn {
          width: 60% !important;
        }
      }
    }
  }
}
