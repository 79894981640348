@use '../colors';

.contact-modal-container {
  background-color: rgba(3, 3, 3, 0.926);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  // height: 50%;
  //width: 50%;
  z-index: 99999;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    width: 50%;
    padding: 20px 0%;
    background-color: colors.$yellow-main;

    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > h3 {
      font-size: 3rem;
      border-bottom: 1px solid black;
      width: 100%;
      padding-bottom: 10px;
      text-align: center;
      margin-bottom: 20px;
    }
    & > p {
      font-size: 1.5rem;
      padding: 0px 10px;
      text-align: center;
      width: 100%;
    }
    & > button {
      margin-top: 20px;
      width: 35%;
      padding: 10px;
      font-size: 1.3rem;
      border-radius: 15px;
      background-color: colors.$background;
      color: white;
      cursor: pointer;
    }
  }
}
