@use '../colors';
@use '../responsive';
@use '../animations';
.home-page {
  //sekcija za meni
  .meni-section {
    min-height: 100vh;
    padding-top: 0.1px;
    //naslov MENI

    h1.meni-heading {
      color: colors.$yellow-main;

      text-align: center;
      margin-top: 50px;
      // margin-bottom: 10px;

      font-size: 4rem;
    }
    h2.description-meni {
      font-size: 1.7rem;
      text-align: center;
      color: white;
      margin-bottom: 20px;
    }
    // primarni i sekundarni tekst svakog itema
    .menu-item-primary-text {
      font-size: 1.8rem;
      color: colors.$yellow-main;
    }
    .menu-item-secondary-text {
      font-size: 1.2rem;
      color: white;
    }

    //naslov izaberi burger i izaberi velicinu
    h2.meni-header {
      text-align: center;
      color: colors.$yellow-main;
      font-size: 2.2rem;
      margin-bottom: 30px;
    }
    //kontejner svih stavki menija
    .meni-items-container {
      // outline: 1px solid yellow;
      margin: 0 auto;
      width: 95%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      //naslov izaberi burger i izaberi velicinu
      // h2.meni-header {
      //   text-align: center;
      //   color: colors.$yellow-main;
      //   font-size: 2.5rem;
      //   margin-bottom: 30px;
      // }

      //tipovi burgera
      .burger-types-container {
        //outline: 1px solid yellow;
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: center;
        //pojedinacni burger menija
        .burger-single-item-container {
          //outline: 1px solid yellow;
          border-radius: 15px;

          transition: background-color 0.6s linear;

          width: 100%;
          margin-bottom: 5px;

          position: relative;

          display: flex;
          cursor: pointer;
          //slicica itema
          .burger-item-img-wrapper {
            width: 25%;

            img {
              width: 100%;
            }
          }

          //item info
          .burger-item-info {
            color: colors.$yellow-main;
            //padding-top: 13px;

            padding: 15px;
            // .burger-item-title {
            //   font-size: 2rem;
            // }
            // .burger-item-ingredients {
            // }
          }
        }
        //selektovani burger
        .selected {
          background-color: rgb(240, 180, 107);

          box-shadow: inset 0px 0px 6px 0px black;

          border: 1px solid colors.$yellow-main;
          .burger-item-info {
            .burger-item-title {
              color: black;
            }
            .burger-item-ingredients {
              color: black;
            }
          }

          &::after {
            height: 0px;
          }
        }
        //desna strelica
        .right-arrow {
          position: absolute;
          width: 20px;
          height: 20px;
          right: -35px;
          top: 50%;
          transform: translateY(-50%);
        }
        .price-range {
          display: none;
        }
        .jello {
          @include animations.jello-horizontal(-50%);
          -webkit-animation: jello-horizontal 1s both infinite;
          animation: jello-horizontal 1.2s both infinite;
        }
      }
      // Velicine u meniju
      .sizes-container {
        width: 48%;
        //  outline: 1px solid yellow;
        padding: 0px 20px;

        h2 {
          margin-bottom: 40px;
        }
      }
    }
    //pojedinacna velicina
    .size-single-item-container {
      margin-bottom: 15px;
      //prvi red
      .size-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.5rem;

        // .size-name {
        //   color: colors.$yellow-main;
        // }
        .separator {
          flex-grow: 1;
          margin: 0px 10px;
          height: 2px;
          background-color: colors.$yellow-main;
        }
        .burger-price {
          color: white;
        }
      }

      //drugi red - sastojci
      // .ingredients {
      //   color: white;
      // }
    }
    //PRILOZI
    .side-dish-container {
      //naslov odnosno tabovi, pomfrit dezert i pica
      .types-of-side-dish {
        ul {
          display: flex;
          justify-content: center;
          align-items: stretch;
          padding: 0px;
          position: relative;

          // pointer-events: none;
          .selected-square {
            top: 0px;
            left: 0px;
            width: 33.33%;
            height: 100%;
            border-radius: 5px;
            background-color: colors.$yellow-main;
            position: absolute;
            z-index: 1;
            transition: all 0.5s cubic-bezier(0.24, 0.91, 1, 0.97);
            pointer-events: auto;
            // box-shadow: inset 0px 0px 5px 0px black;
          }

          li {
            box-shadow: inset 1px -4px 12px -4px black;

            width: 33.33%;
            text-align: center;
            color: white;
            font-size: 1.7rem;
            padding: 30px 10px;

            z-index: 2;
            cursor: pointer;
            &.selected {
              // background-color: colors.$yellow-main;
              color: black;
            }
            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;
            // &::after {
            //   content: '';
            //   right: 0px;
            //   top: 50%;
            //   width: 2px;
            //   height: 80%;
            //   position: absolute;

            //   transform: translateY(-50%);
            //   background-color: colors.$yellow-main;
            // }
          }
        }
      }

      //konkretni itemi kontejner
      .side-dish-items-container {
        margin: 0 auto;
        margin-top: 10px;
        // padding: 0px 20px;
        width: 300%;
        position: relative;
        display: flex;
        transition: transform 0.5s cubic-bezier(0.05, 0.68, 0.65, 0.87);
        &.center {
          transform: translateX(-33.33%);
        }
        &.right {
          transform: translateX(-66.66%);
        }

        //prilozi

        // Pojedinacni kontejner za svaki tip
        .side-dish-single-type-container {
          width: 100%;
          padding: 0px 2%;
        }

        .size-single-item-container {
          .size-name {
            font-size: 2rem;
            max-width: 70%;
          }
        }
      }
    }
  }
}

//RESPONSIVE
@media screen and (max-width: responsive.$small) {
  .home-page {
    .meni-section {
      //MENI NASLOV
      h1.meni-heading {
        margin-top: 30px;
        font-size: 5rem;
      }
      .meni-items-container {
        flex-direction: column;

        //naslovi
        h2.meni-header {
          font-size: 3rem;
        }
        .menu-item-primary-text {
          font-size: 2.2rem;
          color: colors.$yellow-main;
        }
        .menu-item-secondary-text {
          font-size: 1.5rem;
          color: white;
        }
        //tipovi burgera
        .burger-types-container {
          width: 100%;
          margin-bottom: 30px;

          //single burger
          .burger-single-item-container {
            width: 90%;
            //item info
            .burger-item-info {
            }

            //kada je selektovan
            &.selected {
              width: 80%;
              transform: translateX(-13%);

              .price-range {
                position: absolute;
                display: block;
                width: 30%;
                height: auto;
                right: -30%;
                top: 27%;
                color: white;
                font-size: 1.2rem;

                text-align: center;
              }
            }
          }

          //desna strelica
          .right-arrow {
            transform: translateY(-50%) rotate(90deg);
            right: -19%;
            top: 60%;
          }
          .jello {
            @include animations.jello-vertical(-50%);
            -webkit-animation: jello-vertical 1s both infinite;
            animation: jello-vertical 1.2s both infinite;
          }
        }

        //velicine u meniju
        .sizes-container {
          width: 100%;
        }
      }

      //prilozi
      .side-dish-container {
        .types-of-side-dish {
          ul {
            li {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
