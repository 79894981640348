@use './colors';
@use './responsive';
//** RIGHT Nav je lista; LEFT NAV je logo //
nav.navbar {
  position: fixed;
  top: 0px;
  left: 0px;

  max-height: 13vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  width: 100%;
  box-shadow: 1px -4px 10px black;
  // backdrop-filter: blur(40px);
  background-color: colors.$background;
  padding: 10px 0px;

  transition: padding 0.5s linear;
  //collapsed, when user scrolls down
  &.collapsed {
    padding: 0px 0px;
    // max-height: 10vh;
    .left-nav {
      // width: 6%;

      .logo-nav-wrapper {
        img {
          // max-height: 9vh;
        }
      }
    }
    .right-nav {
      ul {
        li {
          // font-size: 1.2rem;
        }
      }
    }
    .nav-poruci {
      button {
        // font-size: 1.2rem;
      }
    }
  }
  .left-nav {
    // transition: all 0.5s linear;
    width: 8%;
    margin-left: 2%;

    .logo-nav-wrapper {
      width: 100%;
      cursor: pointer;
      img {
        // transition: all 0.5s linear;
        max-width: 100%;
        max-height: 9vh;
      }
    }
  }
  .nav-collapsed-content {
    display: flex;
    justify-content: space-between;
    // margin-right: 10%;
    width: 90%;
    transition: all 0.5s cubic-bezier(0, 1.16, 1, 0.97);

    position: relative;
    .right-nav {
      margin-left: 18%;

      ul {
        display: flex;

        li {
          margin-right: 5%;
          padding: 0px 20px;
          font-size: 1.4rem;
          cursor: pointer;
          position: relative;

          color: white;
          transition: all 0.5s linear;

          &::before {
            content: '';
            position: absolute;
            width: 0%;
            top: 100%;
            left: 0%;
            height: 4px;

            border-radius: 15px;

            background-color: white;

            transition: all 0.4s linear;
          }

          &:hover::before {
            width: 100%;
          }
          a {
            color: white;
          }
        }
      }
    }

    // Poruci posebna stavka
    .nav-poruci {
      // margin-left: auto;

      position: absolute;
      top: 50%;
      right: 3%;
      transform: translateY(-50%);

      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        font-size: 1.4rem;

        &:hover a {
          color: colors.$yellow-main;
          width: 100%;
          height: 100%;
          display: block;
        }
        a {
          width: 100%;
          height: 100%;

          padding: 10px 20px;
          display: block;
          // background-color: red;
        }
      }
      .glovo-wrapper {
        width: 70px;
        height: 50px;
        border-radius: 15px;

        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 15px;

        line-height: 0px;
        // display: none;
        img {
          width: 100%;
          border-radius: 15px;
        }
      }
    }
  }
  // TOGGLE MENI MOBILNI MOD
  .nav-toggle-button {
    display: none;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    img {
      width: 50px;
      height: 50px;
    }
  }
}

//RESPONSIVNOST
@media screen and (max-width: responsive.$extraextralarge) {
  nav.navbar {
    .left-nav {
      width: 10%;
    }
  }
}
//burger menu
@media screen and (max-width: responsive.$large) {
  nav.navbar {
    flex-direction: column;
    justify-content: flex-start;
    max-height: 50vh;
    // Kada se skroluje, tj kada se smanji meni
    &.collapsed {
      .left-nav {
        width: 23%;
      }
      .right-nav {
        ul {
          li {
            font-size: 1.4rem;
          }
        }
      }
      .nav-poruci {
        button {
          font-size: 1.4rem;
        }
      }
    }
    .left-nav {
      width: 25%;
      align-self: flex-start;
    }
    .nav-collapsed-content {
      position: absolute;
      top: 99%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-color: #262623;
      // background-color: transparent;
      // backdrop-filter: blur(20px);
      width: 100%;
      height: 100vh;
      transform: translateX(100%);

      &.show-collapsed {
        transform: translateX(0%);
      }
      .right-nav {
        margin: 0px;
        width: 100%;
        // display: none;
        ul {
          flex-direction: column;
          align-items: center;
          padding: 0px;

          li {
            width: 100%;
            text-align: center;
            padding: 20px 0px;
            margin-right: 0px;
            font-size: 2rem;
            &::before {
              display: none;
            }
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              // padding: 20px 0px;
            }
          }
        }
      }
      .nav-poruci {
        // display: none;

        position: static;

        width: 40%;
        margin: 0 auto;
        margin-top: 10px;

        margin-bottom: 20px;

        text-align: center;

        flex-direction: column;

        transform: translateY(0%);

        button {
          display: none;
          width: 70%;
        }
        a {
          font-size: 2rem;
        }

        .glovo-wrapper {
          margin-top: 25px;
          display: block;
          width: 70%;
          height: 60px;

          overflow: hidden;

          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 0px;
          margin-right: 0px;
          img {
            // max-height: 50px;
          }
        }
      }
    }
    .nav-toggle-button {
      display: block;
    }
  }
}
