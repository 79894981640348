@use '../colors' as colors;
@use '../responsive' as responsive;
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;500;700&display=swap');
@mixin disperse-keyFrame($name, $left, $top) {
  @keyframes #{$name} {
    0% {
      left: 50%;
      top: 50%;
      opacity: 0;
    }

    100% {
      left: $left;
      top: $top;
      opacity: 1;
    }
  }
}

.home-page {
  //landing sekcija
  overflow: hidden;
  .landing-section {
    height: 100vh;
    position: relative;
    overflow: hidden;

    // outline: 1px solid red;

    //gradient pozadina, uklonjeno
    svg.svg-background {
      height: 120%;
      width: 120%;
      position: fixed;
      line-height: 0;
      z-index: -50;
    }
    //LOADER
    .loader-container {
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 99999;
      display: flex;
      background-color: rgb(46, 41, 41);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg[aria-label='loading'] {
        width: 35vw;
        height: 35vh;
        // transform: translate(-50%, -50%);
      }
      h1.loader-title {
        color: colors.$yellow-main;

        font-size: 3rem;
        letter-spacing: 10px;
      }
    }
    div[data-testid='three-dots-loading'] {
      svg {
        height: 100%;
        width: 100%;
        position: fixed;
      }
    }
    //burger sa leve stranice

    .burger-landing-left-wrapper {
      position: fixed;
      left: 0%;
      bottom: 0px;
      width: 50%;
      height: 85vh;
      z-index: 3;
      transition: transform 0.5s ease-in-out;

      // animation-name: smash;
      // animation-duration: 0.8s;
      // animation-timing-function: cubic-bezier(0, 1.3, 0.43, 1.02);
      // animation-iteration-count: 1;

      // transform: translateX(4%);
      line-height: 0px;

      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      &.collapsed {
        transform: translateX(-200%);
      }
    }
    //SMASH UDARAC ANIMACIJA
    @keyframes smash {
      0% {
        transform: scale(10);
      }
      100% {
        transform: scale(1);
      }
    }
    //landing content
    & > .landing-content {
      position: absolute;
      top: 54%;
      right: 10%;
      width: 50%;
      transform: translateX(-0%) translateY(-50%);
      h1 {
        color: colors.$yellow-main;

        text-align: right;
        letter-spacing: 4px;

        z-index: 3;
        font-size: 7.5rem;
        // font-family: 'Signika Negative';

        font-weight: 700;
      }
      h1.heading-smaller {
        text-align: right;
        color: white;
        font-size: 5rem;

        letter-spacing: 1px;
      }
      //dugmici za navigaciju
      .landing-buttons {
        display: flex;
        justify-content: flex-end;

        padding-top: 15px;
        // button.landing-button:first-child {
        //   margin-right: 10%;
        // }

        //dugme za meni
        button.landing-button {
          width: 40%;

          font-size: 2rem;
          padding: 1rem 2rem;
          color: rgb(22, 21, 21);
          &:hover {
            color: colors.$yellow-main;
            a {
              color: colors.$yellow-main;
            }
          }
          a {
            display: inline-block;
            width: 100%;
            // height: 100%;
            // padding: 1rem 2rem;
          }
        }
      }
    }
  }
}

//responsive

//EXTRAEXTRA
@media screen and (max-width: responsive.$extraextralarge) {
  .home-page {
    .landing-section {
      //burger sa leve strane
      .burger-landing-left-wrapper {
        width: 60%;
        left: -13%;
      }

      //tekst sa desne strane
      & > .landing-content {
        width: 70%;
        right: 5%;
      }
    }
  }
}
//EXTRALARGE
@media screen and (max-width: responsive.$extralarge) {
  html {
    // font-size: 20px !important;
  }
  .home-page {
    .landing-section {
      //burger sa leve strane
      .burger-landing-left-wrapper {
        width: 70%;
        left: -30%;
      }

      //tekst sa desne strane
      & > .landing-content {
        width: 70%;
        right: 5%;
      }
    }
  }
}
//large
@media screen and (max-width: responsive.$large) {
  html {
    // font-size: 17px !important;
  }
  .home-page {
    .landing-section {
      //burger sa leve strane
      .burger-landing-left-wrapper {
        width: 80%;
        left: -40%;
      }

      //tekst sa desne strane
      & > .landing-content {
        width: 70%;
        right: 5%;

        h1.heading-smaller:first-child {
          font-size: 4.9rem;
        }
        -webkit-text-size-adjust: none;
        h1 {
          margin-top: 10px;
          font-size: 6.6rem;
        }
        h1.heading-smaller {
          margin-top: 10px;
          font-size: 3.2rem;
          letter-spacing: 1px;
          // margin-bottom: 25px;
        }
      }
    }
  }
}
//medium
@media screen and (max-width: responsive.$medium) {
  html {
    font-size: 14px !important;
  }
  .home-page {
    .landing-section {
      //burger sa leve strane
      .burger-landing-left-wrapper {
        width: 90%;
        left: -50%;
      }

      //tekst sa desne strane
      & > .landing-content {
        width: 70%;
        right: 5%;
      }
    }
  }
}
//medium
@media screen and (max-width: responsive.$small) {
  html {
    font-size: 11px !important;
  }
  .home-page {
    .landing-section {
      //burger sa leve strane
      .burger-landing-left-wrapper {
        width: 160%;
        left: -100%;
      }

      //tekst sa desne strane
      & > .landing-content {
        width: 85%;
        right: 2%;
        top: 48%;

        h1.heading-smaller:first-child {
          font-size: 4.8rem;
          margin-bottom: 0px;
        }
        -webkit-text-size-adjust: none;
        h1 {
          margin-top: 10px;
          font-size: 6.5rem;
        }
        h1.heading-smaller {
          margin-top: 10px;
          font-size: 2.5rem;
          letter-spacing: 1px;
          margin-bottom: 25px;
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  html {
    font-size: 11px !important;
  }
  .home-page {
    .landing-section {
      //burger sa leve strane
      .burger-landing-left-wrapper {
        width: 180%;
        left: -120%;
      }

      //tekst sa desne strane
      & > .landing-content {
        width: 95%;
        right: 2%;
        top: 50%;
        h1 {
          margin-top: 10px;
        }
        .heading-smaller {
          margin-top: 10px;
          font-size: 2.5rem;
          letter-spacing: 1px;
        }
      }
    }
  }
}
// $breakpoint-extraextralarge: 1400px;
// $breakpoint-extralarge: 1200px;
// $breakpoint-large: 992px;
// $breakpoint-medium: 768px;
// $breakpoint-small: 576px;
