.contact-modal-container {
  background-color: rgba(3, 3, 3, 0.926);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-modal-container .modal-content {
  width: 50%;
  padding: 20px 0%;
  background-color: #ffb527;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact-modal-container .modal-content > h3 {
  font-size: 3rem;
  border-bottom: 1px solid black;
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.contact-modal-container .modal-content > p {
  font-size: 1.5rem;
  padding: 0px 10px;
  text-align: center;
  width: 100%;
}
.contact-modal-container .modal-content > button {
  margin-top: 20px;
  width: 35%;
  padding: 10px;
  font-size: 1.3rem;
  border-radius: 15px;
  background-color: #262623;
  color: white;
  cursor: pointer;
}

.contact-section {
  min-height: 100vh;
  padding-top: 0.1px;
}
.contact-section h1 {
  margin-top: 100px;
  text-align: center;
  font-size: 4rem;
  color: #ffb527;
  letter-spacing: 2px;
  margin-bottom: 25px;
}
.contact-section h3.contact-heading {
  text-align: center;
  font-size: 2rem;
  color: #ffb527;
}
.contact-section .contact-content {
  display: flex;
  color: #ffb527;
  padding: 0px 100px;
  margin-bottom: 100px;
}
.contact-section .contact-content .left-info-container {
  width: 50%;
}
.contact-section .contact-content .left-info-container > h3 {
  color: white;
}
.contact-section .contact-content .left-info-container .info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-section .contact-content .left-info-container .info-wrapper .single-contact-info {
  width: 60%;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .contact-img-wrapper {
  width: 20%;
  padding-top: 0.5%;
  line-height: 0px;
}
.contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .contact-img-wrapper img {
  width: 100%;
}
.contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .text-description {
  margin-left: 10%;
}
.contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .text-description h5 {
  font-size: 1.4rem;
}
.contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .text-description p {
  color: white;
}
.contact-section .contact-content .right-contact-form-container {
  width: 50%;
}
.contact-section .contact-content .right-contact-form-container form {
  margin-top: 15px;
}
.contact-section .contact-content .right-contact-form-container form div.form-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.contact-section .contact-content .right-contact-form-container form div.form-row label {
  width: 100%;
  font-size: 1.4rem;
  padding-top: 10px;
}
.contact-section .contact-content .right-contact-form-container form div.form-row input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  font-size: 1.4rem;
}
.contact-section .contact-content .right-contact-form-container form div.form-row input:focus, .contact-section .contact-content .right-contact-form-container form div.form-row input:active {
  outline: none;
  border: none;
  border-bottom: 3px solid #ffb527;
}
.contact-section .contact-content .right-contact-form-container form div.form-row textarea {
  width: 100%;
  height: 110px;
  border: 3px solid white;
  color: white;
  background-color: transparent;
  resize: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 1.1rem;
}
.contact-section .contact-content .right-contact-form-container form div.form-row textarea:focus,
.contact-section .contact-content .right-contact-form-container form div.form-row textarea active {
  outline: none;
  border: 4px solid #ffb527;
}
.contact-section .contact-content .right-contact-form-container form .submit-message-btn {
  margin: 0 auto;
  display: block;
  width: 40%;
  padding: 18px;
  font-size: 1.4rem;
  cursor: pointer;
}
.contact-section .map-container h3 {
  margin-bottom: 8px;
}
.contact-section .map-container h3.adress-heading {
  color: #ffb527;
  margin-bottom: 20px;
}
.contact-section .map-container #map-heading {
  color: white;
}
.contact-section .map-container #map-heading strong {
  color: #ffb527;
  font-size: 2.2rem;
}
.contact-section .map-container iframe {
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 1200px) {
  .contact-content {
    padding-left: 0px !important;
    padding-right: 30px !important;
    justify-content: center;
  }
}
@media screen and (max-width: 576px) {
  .contact-section .contact-content {
    flex-direction: column;
    align-items: center;
    padding: 0px 5%;
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .contact-section .contact-content .left-info-container {
    width: 100%;
    margin-bottom: 65px;
  }
  .contact-section .contact-content .left-info-container .info-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .contact-section .contact-content .left-info-container .info-wrapper .single-contact-info {
    width: 90%;
  }
  .contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .contact-img-wrapper {
    width: 18%;
    margin-right: 8%;
  }
  .contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .text-description h5 {
    font-size: 1.8rem;
  }
  .contact-section .contact-content .left-info-container .info-wrapper .single-contact-info .text-description p {
    font-size: 1.4rem;
  }
  .contact-section .contact-content .right-contact-form-container {
    width: 90%;
  }
  .contact-section .contact-content .right-contact-form-container .contact-heading {
    font-size: 3rem;
  }
  .contact-section .contact-content .right-contact-form-container .form-row label {
    font-size: 1.6rem !important;
  }
  .contact-section .contact-content .right-contact-form-container .form-row textarea {
    font-size: 1.3rem !important;
  }
  .contact-section .contact-content .right-contact-form-container .submit-message-btn {
    width: 60% !important;
  }
}

