@use '../navbar';
@use '../footer';
@use './landing';
@use './burger-list';

@use '../colors';
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;500;700&display=swap');

input,
textarea,
button,
select,
div,
section a {
  -webkit-tap-highlight-color: transparent;
}
html {
  font-size: 16px;
  width: 100%;
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: colors.$background;
}
* {
  font-family: 'Signika Negative', 'Calibri', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
ul {
  list-style-type: none;
}
p {
  margin: 0px;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}
.home-page {
  //background-color: red;
}

.btn-classic {
  background-color: colors.$yellow-main;
  color: colors.$background;
  padding: 0px;
  border-radius: 10px;

  position: relative;

  border: 1px solid transparent;
  transition: color 0.4s linear;

  z-index: 1;
  display: inline-block;
  cursor: pointer;

  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 0%;
    background-color: #262623;
    border-radius: 10px;
    z-index: -1;
    transition: all 0.3s linear;
  }
  // &::after {
  //   content: 'Meni';
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -250%);
  //   transition: all 0.6s linear;
  //   color: colors.$yellow-main;
  // }
  a {
    // height: 200%;
    transition: all 0.5s linear;
  }
  &:hover a {
    // transform: translateY(100%);
  }
  &:hover::after {
    transform: translate(-50%, -50%);
  }
  &:hover::before {
    border: 1px solid colors.$yellow-main;

    width: 100%;

    color: colors.$yellow-main;
  }
  &:hover {
    color: colors.$yellow-main;
  }
}
