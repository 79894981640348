@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;500;700&display=swap");
nav.navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  max-height: 13vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  width: 100%;
  box-shadow: 1px -4px 10px black;
  background-color: #262623;
  padding: 10px 0px;
  transition: padding 0.5s linear;
}
nav.navbar.collapsed {
  padding: 0px 0px;
}
nav.navbar .left-nav {
  width: 8%;
  margin-left: 2%;
}
nav.navbar .left-nav .logo-nav-wrapper {
  width: 100%;
  cursor: pointer;
}
nav.navbar .left-nav .logo-nav-wrapper img {
  max-width: 100%;
  max-height: 9vh;
}
nav.navbar .nav-collapsed-content {
  display: flex;
  justify-content: space-between;
  width: 90%;
  transition: all 0.5s cubic-bezier(0, 1.16, 1, 0.97);
  position: relative;
}
nav.navbar .nav-collapsed-content .right-nav {
  margin-left: 18%;
}
nav.navbar .nav-collapsed-content .right-nav ul {
  display: flex;
}
nav.navbar .nav-collapsed-content .right-nav ul li {
  margin-right: 5%;
  padding: 0px 20px;
  font-size: 1.4rem;
  cursor: pointer;
  position: relative;
  color: white;
  transition: all 0.5s linear;
}
nav.navbar .nav-collapsed-content .right-nav ul li::before {
  content: "";
  position: absolute;
  width: 0%;
  top: 100%;
  left: 0%;
  height: 4px;
  border-radius: 15px;
  background-color: white;
  transition: all 0.4s linear;
}
nav.navbar .nav-collapsed-content .right-nav ul li:hover::before {
  width: 100%;
}
nav.navbar .nav-collapsed-content .right-nav ul li a {
  color: white;
}
nav.navbar .nav-collapsed-content .nav-poruci {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
nav.navbar .nav-collapsed-content .nav-poruci button {
  font-size: 1.4rem;
}
nav.navbar .nav-collapsed-content .nav-poruci button:hover a {
  color: #ffb527;
  width: 100%;
  height: 100%;
  display: block;
}
nav.navbar .nav-collapsed-content .nav-poruci button a {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: block;
}
nav.navbar .nav-collapsed-content .nav-poruci .glovo-wrapper {
  width: 70px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-right: 15px;
  line-height: 0px;
}
nav.navbar .nav-collapsed-content .nav-poruci .glovo-wrapper img {
  width: 100%;
  border-radius: 15px;
}
nav.navbar .nav-toggle-button {
  display: none;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}
nav.navbar .nav-toggle-button img {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 1400px) {
  nav.navbar .left-nav {
    width: 10%;
  }
}
@media screen and (max-width: 992px) {
  nav.navbar {
    flex-direction: column;
    justify-content: flex-start;
    max-height: 50vh;
  }
  nav.navbar.collapsed .left-nav {
    width: 23%;
  }
  nav.navbar.collapsed .right-nav ul li {
    font-size: 1.4rem;
  }
  nav.navbar.collapsed .nav-poruci button {
    font-size: 1.4rem;
  }
  nav.navbar .left-nav {
    width: 25%;
    align-self: flex-start;
  }
  nav.navbar .nav-collapsed-content {
    position: absolute;
    top: 99%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #262623;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
  }
  nav.navbar .nav-collapsed-content.show-collapsed {
    transform: translateX(0%);
  }
  nav.navbar .nav-collapsed-content .right-nav {
    margin: 0px;
    width: 100%;
  }
  nav.navbar .nav-collapsed-content .right-nav ul {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
  nav.navbar .nav-collapsed-content .right-nav ul li {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    margin-right: 0px;
    font-size: 2rem;
  }
  nav.navbar .nav-collapsed-content .right-nav ul li::before {
    display: none;
  }
  nav.navbar .nav-collapsed-content .right-nav ul li a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  nav.navbar .nav-collapsed-content .nav-poruci {
    position: static;
    width: 40%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    flex-direction: column;
    transform: translateY(0%);
  }
  nav.navbar .nav-collapsed-content .nav-poruci button {
    display: none;
    width: 70%;
  }
  nav.navbar .nav-collapsed-content .nav-poruci a {
    font-size: 2rem;
  }
  nav.navbar .nav-collapsed-content .nav-poruci .glovo-wrapper {
    margin-top: 25px;
    display: block;
    width: 70%;
    height: 60px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    margin-right: 0px;
  }
  nav.navbar .nav-toggle-button {
    display: block;
  }
}
.footer .footer-content-row {
  padding: 60px 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer .footer-content-row .logo-wrapper {
  order: 2;
  width: 20%;
}
.footer .footer-content-row .logo-wrapper img {
  width: 100%;
  animation: pulse 1.5s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1.15);
  }
  70% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.15);
  }
}
.footer .footer-content-row .delivery-info {
  width: 20%;
  order: 1;
  display: flex;
}
.footer .footer-content-row .delivery-info .glovo-wrapper {
  margin: 0 auto;
  width: 45%;
  border-radius: 30%;
  overflow: hidden;
  line-height: 0px;
}
.footer .footer-content-row .delivery-info .glovo-wrapper img {
  width: 100%;
  height: 100%;
}
.footer .footer-content-row .delivery-info p {
  margin-top: 15px;
  color: white;
  font-size: 2rem;
}
.footer .footer-content-row .social-network-container {
  order: 3;
  width: 20%;
}
.footer .footer-content-row .social-network-container .social-network-images {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.footer .footer-content-row .social-network-container .social-network-images .social-wrapper {
  width: 25%;
  line-height: 0px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.footer .footer-content-row .social-network-container .social-network-images .social-wrapper img {
  width: 100%;
}
.footer .footer-content-row .social-network-container .social-network-images .social-wrapper:nth-child(2) {
  border-radius: 12px;
}
.footer .footer-content-row .social-network-container .social-network-images .social-wrapper:hover {
  background-color: #ffb527;
}
.footer .footer-content-row .social-network-container p {
  color: white;
  font-size: 2rem;
  text-align: center;
}
.footer .footer-copyright {
  background-color: #ffb527;
  padding: 30px;
  font-size: 2rem;
}

@media screen and (max-width: 576px) {
  .footer .footer-content-row {
    flex-direction: column;
    align-items: center;
  }
  .footer .footer-content-row .logo-wrapper {
    width: 50%;
    order: 1;
    margin-bottom: 60px;
    margin-left: 0px;
  }
  .footer .footer-content-row .delivery-info {
    width: 80%;
    order: 2;
    margin-bottom: 30px;
  }
  .footer .footer-content-row .delivery-info .glovo-wrapper {
    width: 40%;
    margin: 0 auto;
  }
  .footer .footer-content-row .social-network-container {
    width: 90%;
  }
  .footer .footer-content-row .social-network-container .social-network-images {
    width: 50%;
    margin: 0 auto;
    margin-left: auto;
    margin-top: 40px;
  }
  .footer .footer-content-row .social-network-container p {
    margin-top: 0px;
    text-align: right;
  }
  .footer .footer-copyright {
    font-size: 1.5rem;
  }
}
.home-page {
  overflow: hidden;
}
.home-page .landing-section {
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.home-page .landing-section svg.svg-background {
  height: 120%;
  width: 120%;
  position: fixed;
  line-height: 0;
  z-index: -50;
}
.home-page .landing-section .loader-container {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  display: flex;
  background-color: rgb(46, 41, 41);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-page .landing-section .loader-container svg[aria-label=loading] {
  width: 35vw;
  height: 35vh;
}
.home-page .landing-section .loader-container h1.loader-title {
  color: #ffb527;
  font-size: 3rem;
  letter-spacing: 10px;
}
.home-page .landing-section div[data-testid=three-dots-loading] svg {
  height: 100%;
  width: 100%;
  position: fixed;
}
.home-page .landing-section .burger-landing-left-wrapper {
  position: fixed;
  left: 0%;
  bottom: 0px;
  width: 50%;
  height: 85vh;
  z-index: 3;
  transition: transform 0.5s ease-in-out;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.home-page .landing-section .burger-landing-left-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.home-page .landing-section .burger-landing-left-wrapper.collapsed {
  transform: translateX(-200%);
}
@keyframes smash {
  0% {
    transform: scale(10);
  }
  100% {
    transform: scale(1);
  }
}
.home-page .landing-section > .landing-content {
  position: absolute;
  top: 54%;
  right: 10%;
  width: 50%;
  transform: translateX(0%) translateY(-50%);
}
.home-page .landing-section > .landing-content h1 {
  color: #ffb527;
  text-align: right;
  letter-spacing: 4px;
  z-index: 3;
  font-size: 7.5rem;
  font-weight: 700;
}
.home-page .landing-section > .landing-content h1.heading-smaller {
  text-align: right;
  color: white;
  font-size: 5rem;
  letter-spacing: 1px;
}
.home-page .landing-section > .landing-content .landing-buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}
.home-page .landing-section > .landing-content .landing-buttons button.landing-button {
  width: 40%;
  font-size: 2rem;
  padding: 1rem 2rem;
  color: rgb(22, 21, 21);
}
.home-page .landing-section > .landing-content .landing-buttons button.landing-button:hover {
  color: #ffb527;
}
.home-page .landing-section > .landing-content .landing-buttons button.landing-button:hover a {
  color: #ffb527;
}
.home-page .landing-section > .landing-content .landing-buttons button.landing-button a {
  display: inline-block;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .home-page .landing-section .burger-landing-left-wrapper {
    width: 60%;
    left: -13%;
  }
  .home-page .landing-section > .landing-content {
    width: 70%;
    right: 5%;
  }
}
@media screen and (max-width: 1200px) {
  .home-page .landing-section .burger-landing-left-wrapper {
    width: 70%;
    left: -30%;
  }
  .home-page .landing-section > .landing-content {
    width: 70%;
    right: 5%;
  }
}
@media screen and (max-width: 992px) {
  .home-page .landing-section .burger-landing-left-wrapper {
    width: 80%;
    left: -40%;
  }
  .home-page .landing-section > .landing-content {
    width: 70%;
    right: 5%;
    -webkit-text-size-adjust: none;
  }
  .home-page .landing-section > .landing-content h1.heading-smaller:first-child {
    font-size: 4.9rem;
  }
  .home-page .landing-section > .landing-content h1 {
    margin-top: 10px;
    font-size: 6.6rem;
  }
  .home-page .landing-section > .landing-content h1.heading-smaller {
    margin-top: 10px;
    font-size: 3.2rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 14px !important;
  }
  .home-page .landing-section .burger-landing-left-wrapper {
    width: 90%;
    left: -50%;
  }
  .home-page .landing-section > .landing-content {
    width: 70%;
    right: 5%;
  }
}
@media screen and (max-width: 576px) {
  html {
    font-size: 11px !important;
  }
  .home-page .landing-section .burger-landing-left-wrapper {
    width: 160%;
    left: -100%;
  }
  .home-page .landing-section > .landing-content {
    width: 85%;
    right: 2%;
    top: 48%;
    -webkit-text-size-adjust: none;
  }
  .home-page .landing-section > .landing-content h1.heading-smaller:first-child {
    font-size: 4.8rem;
    margin-bottom: 0px;
  }
  .home-page .landing-section > .landing-content h1 {
    margin-top: 10px;
    font-size: 6.5rem;
  }
  .home-page .landing-section > .landing-content h1.heading-smaller {
    margin-top: 10px;
    font-size: 2.5rem;
    letter-spacing: 1px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 350px) {
  html {
    font-size: 11px !important;
  }
  .home-page .landing-section .burger-landing-left-wrapper {
    width: 180%;
    left: -120%;
  }
  .home-page .landing-section > .landing-content {
    width: 95%;
    right: 2%;
    top: 50%;
  }
  .home-page .landing-section > .landing-content h1 {
    margin-top: 10px;
  }
  .home-page .landing-section > .landing-content .heading-smaller {
    margin-top: 10px;
    font-size: 2.5rem;
    letter-spacing: 1px;
  }
}
.home-page .burger-list-section {
  min-height: 100vh;
  overflow: hidden;
  height: auto;
  transition: all 0.2s linear;
}
.home-page .burger-list-section .full-menu-pointer {
  margin: 0 auto;
  margin-top: 100px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.home-page .burger-list-section .full-menu-pointer .wrapper {
  width: 30%;
  animation: bounce 1.3s ease infinite;
  margin-right: 30px;
}
.home-page .burger-list-section .full-menu-pointer .wrapper img {
  width: 100%;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.home-page .burger-list-section .full-menu-pointer p {
  color: white;
  font-size: 2rem;
}
.home-page .burger-list-section > h1 {
  margin-top: 40px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 3rem;
  color: #ffb527;
}
.home-page .burger-list-section.fade-in {
  opacity: 1;
}
.home-page .burger-list-section.fade-in .burger-list-section-content {
  opacity: 1;
  transform: translateX(0%);
}
.home-page .burger-list-section .burger-list-section-content {
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  transform: translateX(50%);
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container {
  width: 50%;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container {
  position: relative;
  box-shadow: 0px 0px 13px 0px black;
  border-radius: 0px 15px 15px 0px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  min-height: 80px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.24, 0.91, 1, 0.97);
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .burger-img-wrapper {
  width: 20%;
  text-align: center;
  margin-right: 10px;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .burger-img-wrapper img {
  max-width: 100%;
  max-height: 90px;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .burger-info h3 {
  color: #ffb527;
  font-size: 1.8rem;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .accordion-arrow-wrapper {
  width: 5%;
  margin-left: auto;
  margin-right: 10%;
  transition: all 0.5s linear;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .accordion-arrow-wrapper img {
  width: 100%;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .best-buy-ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .best-buy-ribbon::before {
  content: "Best buy";
  position: absolute;
  width: 190%;
  height: 32%;
  background-color: #ffb527;
  transform: rotate(45deg) translateY(-25px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .best-buy-ribbon::after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: #f6a200;
  box-shadow: -70px -75px #f6a200;
  z-index: -1;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container.selected {
  transform: translateX(-18%);
  box-shadow: 0px 0px 13px 0px #ffb527;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container.selected .accordion-arrow-wrapper {
  transform: rotate(-90deg);
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .shining-helper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  overflow: hidden;
  pointer-events: none;
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .shining-helper:before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5019607843);
  height: 400px;
  width: 30px;
  border-radius: 50%;
  left: -120px;
  top: -50%;
  transform: skew(-30deg);
}
.home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container.selected .shining-helper::before {
  animation-name: shine-reveal;
  animation-duration: 3s;
  animation-delay: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-timing-function: cubic-bezier(0, 1.26, 1, 1.03);
}
@keyframes shine-reveal {
  0% {
    left: -90px;
  }
  30% {
    left: 110%;
  }
  100% {
    left: 110%;
  }
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container {
  width: 50%;
  transform: translateX(100%);
  transition: all 1s cubic-bezier(0, 1.16, 1, 0.97);
  position: absolute;
  left: 50%;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-img-wrapper {
  text-align: center;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-img-wrapper img {
  max-width: 100%;
  max-height: 40vh;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container {
  padding: 0px 55px;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container h5 {
  font-size: 2rem;
  color: #ffb527;
  text-align: left;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container p {
  font-size: 1.6rem;
  color: white;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container .burger-characteristic {
  margin-top: 30px;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic {
  width: 40%;
  display: flex;
  align-items: center;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic:first-child {
  margin-right: 20px;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic .beef-img-wrapper {
  width: 25%;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic .beef-img-wrapper img {
  width: 100%;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic p {
  margin-left: 10px;
  width: 70%;
  font-size: 1.2rem;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container h6 {
  color: white;
  font-size: 1.9rem;
  font-weight: lighter;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container .burger-ingredients-container h6 span {
  color: #ffb527;
  font-weight: bolder !important;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-right-container.show {
  transform: translateX(0%);
}

.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container {
  display: none;
  max-height: 0px;
  overflow: hidden;
  width: 100%;
  transition: transform 1s cubic-bezier(0, 1.16, 1, 0.97);
  transform: translateX(100%);
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container.expanded {
  transform: translateX(0%);
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-img-wrapper img {
  max-width: 100%;
  max-height: 50vh;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container {
  padding: 0px 55px;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container h5 {
  font-size: 2rem;
  color: #ffb527;
  text-align: left;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container p {
  font-size: 1.6rem;
  color: white;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container .burger-characteristic {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic {
  width: 40%;
  display: flex;
  align-items: center;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic .beef-img-wrapper {
  width: 25%;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic .beef-img-wrapper img {
  width: 100%;
}
.home-page .burger-list-section .burger-list-section-content .big-burger-accordion-container .burger-ingredients-container .burger-characteristic .single-burger-characteristic p {
  margin-left: 10px;
  width: 70%;
  font-size: 1.2rem;
}

@media screen and (max-width: 576px) {
  .home-page .big-burger-right-container {
    display: none;
  }
  .home-page .big-burger-accordion-container {
    display: block !important;
  }
  .home-page .full-menu-pointer {
    width: 70% !important;
  }
  .home-page .burger-list-section .burger-list-section-content {
    justify-content: center;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container {
    width: 95%;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container {
    width: 100%;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .burger-img-wrapper {
    width: 30%;
    text-align: center;
    transition: 1s all linear;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .burger-img-wrapper img {
    max-width: 100%;
    max-height: 75px;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .burger-info h3 {
    color: #ffb527;
    font-size: 2rem;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container .best-buy-ribbon:before {
    font-size: 1.5rem;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .single-burger-container.selected {
    transform: translateX(-30%);
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .big-burger-accordion-container .burger-ingredients-container {
    padding: 0px 30px;
  }
  .home-page .burger-list-section .burger-list-section-content .burger-list-container .big-burger-accordion-container .burger-ingredients-container .burger-characteristic {
    margin-bottom: 30px;
    width: 100%;
  }
}
input,
textarea,
button,
select,
div,
section a {
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 16px;
  width: 100%;
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #262623;
}

* {
  font-family: "Signika Negative", "Calibri", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}

p {
  margin: 0px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.btn-classic {
  background-color: #ffb527;
  color: #262623;
  padding: 0px;
  border-radius: 10px;
  position: relative;
  border: 1px solid transparent;
  transition: color 0.4s linear;
  z-index: 1;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
}
.btn-classic::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 0%;
  background-color: #262623;
  border-radius: 10px;
  z-index: -1;
  transition: all 0.3s linear;
}
.btn-classic a {
  transition: all 0.5s linear;
}
.btn-classic:hover::after {
  transform: translate(-50%, -50%);
}
.btn-classic:hover::before {
  border: 1px solid #ffb527;
  width: 100%;
  color: #ffb527;
}
.btn-classic:hover {
  color: #ffb527;
}

