@use '../colors';
@use '../responsive';
.home-page {
  .burger-list-section {
    min-height: 100vh;
    overflow: hidden;
    height: auto;
    transition: all 0.2s linear;

    //ceo meni pokazivac na dole
    .full-menu-pointer {
      margin: 0 auto;
      margin-top: 100px;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      .wrapper {
        width: 30%;
        animation: bounce 1.3s ease infinite;
        margin-right: 30px;
        img {
          width: 100%;
        }

        @keyframes bounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-30px);
          }
          60% {
            transform: translateY(-15px);
          }
        }
      }
      p {
        color: white;
        font-size: 2rem;
      }
    }
    & > h1 {
      margin-top: 40px;
      margin-bottom: 50px;
      text-align: center;
      font-size: 3rem;
      color: colors.$yellow-main;
    }
    //fade in efekat
    &.fade-in {
      opacity: 1;
      .burger-list-section-content {
        opacity: 1;
        transform: translateX(0%);
      }
    }
    // Wrapper for flex
    .burger-list-section-content {
      display: flex;
      align-items: center;
      position: relative;

      transition: all 0.6s ease-in-out;
      opacity: 0;
      transform: translateX(50%);
      //padding-top: 10px;
      //padding-bottom: 10px;

      //lista burgera sa leve strane

      .burger-list-container {
        // margin-top: 350px;
        width: 50%;
        //pojedinacni burger
        .single-burger-container {
          // outline: 1px solid yellow;
          position: relative;
          box-shadow: 0px 0px 13px 0px black;
          border-radius: 0px 15px 15px 0px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          min-height: 80px;
          // overflow: hidden;
          cursor: pointer;

          transition: all 0.5s cubic-bezier(0.24, 0.91, 1, 0.97);

          //wrapper za red, ne uzima accordion content deo

          //slicica
          .burger-img-wrapper {
            width: 20%;
            text-align: center;
            margin-right: 10px;
            // transition: 1s all linear;
            img {
              // padding: 10px;
              max-width: 100%;
              max-height: 90px;
            }
          }
          //informacije
          .burger-info {
            h3 {
              color: colors.$yellow-main;
              font-size: 1.8rem;
            }
          }
          //accordion strelica
          .accordion-arrow-wrapper {
            width: 5%;
            margin-left: auto;
            margin-right: 10%;
            transition: all 0.5s linear;
            img {
              width: 100%;
            }
          }

          //best buy ribbon
          .best-buy-ribbon {
            position: absolute;
            right: -5px;
            top: -5px;
            width: 90px;
            height: 90px;
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;

            overflow: hidden;
            &::before {
              content: 'Best buy';
              position: absolute;
              width: 190%;
              height: 32%;
              background-color: colors.$yellow-main;
              transform: rotate(45deg) translateY(-25px);

              display: flex;
              justify-content: center;
              align-items: center;

              font-size: 1.1rem;
            }
            &::after {
              content: '';
              position: absolute;

              bottom: 10px;
              right: -5px;

              width: 10px;
              height: 10px;
              background-color: #f6a200;
              box-shadow: -70px -75px #f6a200;
              z-index: -1;
            }
          }

          //IF ELEMENT IS SELECTED
          &.selected {
            // outline: 1px solid red;
            transform: translateX(-18%);
            box-shadow: 0px 0px 13px 0px colors.$yellow-main;

            .burger-img-wrapper {
              // transform: translateX(-10%);
            }

            .accordion-arrow-wrapper {
              transform: rotate(-90deg);
            }
          }

          //SHINE REVEAL EFFECT
          .shining-helper {
            position: absolute;
            width: 100%;
            height: 100%;

            top: 0px;
            left: 0px;
            overflow: hidden;

            pointer-events: none;

            &:before {
              content: '';
              position: absolute;
              background-color: #ffffff80;
              height: 400px;
              width: 30px;
              border-radius: 50%;
              left: -120px;
              top: -50%;
              transform: skew(-30deg);
            }
          }
          &.selected {
            .shining-helper::before {
              animation-name: shine-reveal;
              animation-duration: 3s;
              animation-delay: 0.3s;
              animation-iteration-count: 1;
              animation-fill-mode: backwards;
              animation-timing-function: cubic-bezier(0, 1.26, 1, 1.03);
            }
          }

          @keyframes shine-reveal {
            0% {
              left: -90px;
            }
            30% {
              left: 110%;
            }
            100% {
              left: 110%;
            }
          }
        }
      }
      //big burger on the right side
      .big-burger-right-container {
        width: 50%;
        transform: translateX(100%);
        transition: all 1s cubic-bezier(0, 1.16, 1, 0.97);
        position: absolute;
        left: 50%;
        .burger-img-wrapper {
          text-align: center;

          img {
            max-width: 100%;
            max-height: 40vh;
          }
        }

        //sastojci
        .burger-ingredients-container {
          padding: 0px 55px;
          h5 {
            font-size: 2rem;
            color: colors.$yellow-main;
            text-align: left;
          }
          p {
            font-size: 1.6rem;
            color: white;
          }
          // 100% beef and characteristic with img
          .burger-characteristic {
            margin-top: 30px;
            margin-bottom: 14px;
            display: flex;
            justify-content: center;
            // Pojedinacna karakteristika
            .single-burger-characteristic {
              width: 40%;
              display: flex;
              align-items: center;

              &:first-child {
                margin-right: 20px;
              }
              .beef-img-wrapper {
                width: 25%;
                img {
                  width: 100%;
                }
              }
              p {
                margin-left: 10px;
                width: 70%;
                font-size: 1.2rem;
              }
            }
          }
          // Cena info
          h6 {
            color: white;
            font-size: 1.9rem;
            font-weight: lighter;
            span {
              color: colors.$yellow-main;
              font-weight: bolder !important;
            }
          }
        }

        //When it is selected
        &.show {
          transform: translateX(0%);
        }
      }
    }
  }
}
//accordion content burgera, za mobilni
.home-page {
  .burger-list-section {
    .burger-list-section-content {
      .big-burger-accordion-container {
        display: none;
        max-height: 0px;
        overflow: hidden;
        width: 100%;
        transition: transform 1s cubic-bezier(0, 1.16, 1, 0.97);

        transform: translateX(100%);
        //kada je accordion expandovan
        &.expanded {
          transform: translateX(0%);
        }
        .burger-img-wrapper {
          img {
            max-width: 100%;
            max-height: 50vh;
          }
        }

        //slice dole
        //sastojci
        .burger-ingredients-container {
          padding: 0px 55px;
          h5 {
            font-size: 2rem;
            color: colors.$yellow-main;
            text-align: left;
          }
          p {
            font-size: 1.6rem;
            color: white;
          }
          // 100% beef and characteristic with img
          .burger-characteristic {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            // Pojedinacna karakteristika
            .single-burger-characteristic {
              width: 40%;
              display: flex;
              align-items: center;
              .beef-img-wrapper {
                width: 25%;
                img {
                  width: 100%;
                }
              }
              p {
                margin-left: 10px;
                width: 70%;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}

//RESPONSIVE

@media screen and (max-width: responsive.$small) {
  .home-page {
    // Da se ne vidi ovo desno na mobilnom
    .big-burger-right-container {
      display: none;
    }
    // da se vidi accordion content na mobilnom
    .big-burger-accordion-container {
      display: block !important;
    }
    //

    //pokazivac za ceo meni
    .full-menu-pointer {
      width: 70% !important;
    }
    .burger-list-section {
      //wrapper za flex
      .burger-list-section-content {
        justify-content: center;

        //lista burgera sa leve strane
        .burger-list-container {
          width: 95%;

          //pojedinacni burger
          .single-burger-container {
            width: 100%;

            //slicica
            .burger-img-wrapper {
              width: 30%;
              text-align: center;
              transition: 1s all linear;
              img {
                max-width: 100%;
                max-height: 75px;
              }
            }
            //informacije
            .burger-info {
              h3 {
                color: colors.$yellow-main;
                font-size: 2rem;
              }
            }
            //RIBBON
            .best-buy-ribbon {
              &:before {
                font-size: 1.5rem;
              }
            }
            //IF ELEMENT IS SELECTED
            &.selected {
              // outline: 1px solid red;
              transform: translateX(-30%);
            }
          }

          //accordion content
          .big-burger-accordion-container {
            //sastojci

            .burger-ingredients-container {
              padding: 0px 30px;
              .burger-characteristic {
                margin-bottom: 30px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
