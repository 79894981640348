.home-page .meni-section {
  min-height: 100vh;
  padding-top: 0.1px;
}
.home-page .meni-section h1.meni-heading {
  color: #ffb527;
  text-align: center;
  margin-top: 50px;
  font-size: 4rem;
}
.home-page .meni-section h2.description-meni {
  font-size: 1.7rem;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}
.home-page .meni-section .menu-item-primary-text {
  font-size: 1.8rem;
  color: #ffb527;
}
.home-page .meni-section .menu-item-secondary-text {
  font-size: 1.2rem;
  color: white;
}
.home-page .meni-section h2.meni-header {
  text-align: center;
  color: #ffb527;
  font-size: 2.2rem;
  margin-bottom: 30px;
}
.home-page .meni-section .meni-items-container {
  margin: 0 auto;
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.home-page .meni-section .meni-items-container .burger-types-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-page .meni-section .meni-items-container .burger-types-container .burger-single-item-container {
  border-radius: 15px;
  transition: background-color 0.6s linear;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  cursor: pointer;
}
.home-page .meni-section .meni-items-container .burger-types-container .burger-single-item-container .burger-item-img-wrapper {
  width: 25%;
}
.home-page .meni-section .meni-items-container .burger-types-container .burger-single-item-container .burger-item-img-wrapper img {
  width: 100%;
}
.home-page .meni-section .meni-items-container .burger-types-container .burger-single-item-container .burger-item-info {
  color: #ffb527;
  padding: 15px;
}
.home-page .meni-section .meni-items-container .burger-types-container .selected {
  background-color: rgb(240, 180, 107);
  box-shadow: inset 0px 0px 6px 0px black;
  border: 1px solid #ffb527;
}
.home-page .meni-section .meni-items-container .burger-types-container .selected .burger-item-info .burger-item-title {
  color: black;
}
.home-page .meni-section .meni-items-container .burger-types-container .selected .burger-item-info .burger-item-ingredients {
  color: black;
}
.home-page .meni-section .meni-items-container .burger-types-container .selected::after {
  height: 0px;
}
.home-page .meni-section .meni-items-container .burger-types-container .right-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
}
.home-page .meni-section .meni-items-container .burger-types-container .price-range {
  display: none;
}
.home-page .meni-section .meni-items-container .burger-types-container .jello {
  -webkit-animation: jello-horizontal 1s both infinite;
  animation: jello-horizontal 1.2s both infinite;
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1) translateY(-50%);
    transform: scale3d(1, 1, 1) translateY(-50%);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1) translateY(-50%);
    transform: scale3d(1.25, 0.75, 1) translateY(-50%);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1) translateY(-50%);
    transform: scale3d(0.75, 1.25, 1) translateY(-50%);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1) translateY(-50%);
    transform: scale3d(1.15, 0.85, 1) translateY(-50%);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1) translateY(-50%);
    transform: scale3d(0.95, 1.05, 1) translateY(-50%);
  }
  75% {
    -webkit-transform: scale3d(1, 1, 1) translateY(-50%);
    transform: scale3d(1, 1, 1) translateY(-50%);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1) translateY(-50%);
    transform: scale3d(1, 1, 1) translateY(-50%);
  }
}
.home-page .meni-section .meni-items-container .sizes-container {
  width: 48%;
  padding: 0px 20px;
}
.home-page .meni-section .meni-items-container .sizes-container h2 {
  margin-bottom: 40px;
}
.home-page .meni-section .size-single-item-container {
  margin-bottom: 15px;
}
.home-page .meni-section .size-single-item-container .size-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}
.home-page .meni-section .size-single-item-container .size-info .separator {
  flex-grow: 1;
  margin: 0px 10px;
  height: 2px;
  background-color: #ffb527;
}
.home-page .meni-section .size-single-item-container .size-info .burger-price {
  color: white;
}
.home-page .meni-section .side-dish-container .types-of-side-dish ul {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0px;
  position: relative;
}
.home-page .meni-section .side-dish-container .types-of-side-dish ul .selected-square {
  top: 0px;
  left: 0px;
  width: 33.33%;
  height: 100%;
  border-radius: 5px;
  background-color: #ffb527;
  position: absolute;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.24, 0.91, 1, 0.97);
  pointer-events: auto;
}
.home-page .meni-section .side-dish-container .types-of-side-dish ul li {
  box-shadow: inset 1px -4px 12px -4px black;
  width: 33.33%;
  text-align: center;
  color: white;
  font-size: 1.7rem;
  padding: 30px 10px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.home-page .meni-section .side-dish-container .types-of-side-dish ul li.selected {
  color: black;
}
.home-page .meni-section .side-dish-container .side-dish-items-container {
  margin: 0 auto;
  margin-top: 10px;
  width: 300%;
  position: relative;
  display: flex;
  transition: transform 0.5s cubic-bezier(0.05, 0.68, 0.65, 0.87);
}
.home-page .meni-section .side-dish-container .side-dish-items-container.center {
  transform: translateX(-33.33%);
}
.home-page .meni-section .side-dish-container .side-dish-items-container.right {
  transform: translateX(-66.66%);
}
.home-page .meni-section .side-dish-container .side-dish-items-container .side-dish-single-type-container {
  width: 100%;
  padding: 0px 2%;
}
.home-page .meni-section .side-dish-container .side-dish-items-container .size-single-item-container .size-name {
  font-size: 2rem;
  max-width: 70%;
}

@media screen and (max-width: 576px) {
  .home-page .meni-section h1.meni-heading {
    margin-top: 30px;
    font-size: 5rem;
  }
  .home-page .meni-section .meni-items-container {
    flex-direction: column;
  }
  .home-page .meni-section .meni-items-container h2.meni-header {
    font-size: 3rem;
  }
  .home-page .meni-section .meni-items-container .menu-item-primary-text {
    font-size: 2.2rem;
    color: #ffb527;
  }
  .home-page .meni-section .meni-items-container .menu-item-secondary-text {
    font-size: 1.5rem;
    color: white;
  }
  .home-page .meni-section .meni-items-container .burger-types-container {
    width: 100%;
    margin-bottom: 30px;
  }
  .home-page .meni-section .meni-items-container .burger-types-container .burger-single-item-container {
    width: 90%;
  }
  .home-page .meni-section .meni-items-container .burger-types-container .burger-single-item-container.selected {
    width: 80%;
    transform: translateX(-13%);
  }
  .home-page .meni-section .meni-items-container .burger-types-container .burger-single-item-container.selected .price-range {
    position: absolute;
    display: block;
    width: 30%;
    height: auto;
    right: -30%;
    top: 27%;
    color: white;
    font-size: 1.2rem;
    text-align: center;
  }
  .home-page .meni-section .meni-items-container .burger-types-container .right-arrow {
    transform: translateY(-50%) rotate(90deg);
    right: -19%;
    top: 60%;
  }
  .home-page .meni-section .meni-items-container .burger-types-container .jello {
    -webkit-animation: jello-vertical 1s both infinite;
    animation: jello-vertical 1.2s both infinite;
  }
  @-webkit-keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%);
      transform: scale3d(1, 1, 1) translateY(-50%);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1) translateY(-50%);
      transform: scale3d(0.75, 1.25, 1) translateY(-50%);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1) translateY(-50%);
      transform: scale3d(1.25, 0.75, 1) translateY(-50%);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1) translateY(-50%);
      transform: scale3d(0.85, 1.15, 1) translateY(-50%);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1) translateY(-50%);
      transform: scale3d(1.05, 0.95, 1) translateY(-50%);
    }
    75% {
      -webkit-transform: scale3d(0.95, 1.05, 1) translateY(-50%);
      transform: scale3d(0.95, 1.05, 1) translateY(-50%);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%);
      transform: scale3d(1, 1, 1) translateY(-50%);
    }
  }
  @keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(0.75, 1.25, 1) translateY(-50%) rotate(90deg);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1.25, 0.75, 1) translateY(-50%) rotate(90deg);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(0.85, 1.15, 1) translateY(-50%) rotate(90deg);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1.05, 0.95, 1) translateY(-50%) rotate(90deg);
    }
    75% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
      transform: scale3d(1, 1, 1) translateY(-50%) rotate(90deg);
    }
  }
  .home-page .meni-section .meni-items-container .sizes-container {
    width: 100%;
  }
  .home-page .meni-section .side-dish-container .types-of-side-dish ul li {
    padding: 10px;
  }
}

