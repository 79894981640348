@use './colors';
@use './responsive';
.footer {
  .footer-content-row {
    padding: 60px 30px;
    // height: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    //loogo
    .logo-wrapper {
      order: 2;
      width: 20%;
      // margin-left: 5%;
      // flex-grow: 1;

      img {
        width: 100%;
        animation: pulse 1.5s infinite;
      }

      @keyframes pulse {
        0% {
          transform: scale(1.15);
          // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {
          transform: scale(1.3);
          // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
          transform: scale(1.15);
          // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
      }
    }
    //glovo
    .delivery-info {
      width: 20%;
      order: 1;
      display: flex;
      .glovo-wrapper {
        margin: 0 auto;
        width: 45%;
        border-radius: 30%;
        overflow: hidden;
        line-height: 0px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-top: 15px;
        color: white;
        font-size: 2rem;
      }
    }
    //drustvene mreze
    .social-network-container {
      order: 3;
      width: 20%;

      .social-network-images {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        .social-wrapper {
          width: 25%;
          line-height: 0px;
          cursor: pointer;
          transition: all 0.2s linear;
          img {
            width: 100%;
          }
          &:nth-child(2) {
            border-radius: 12px;
          }
          &:hover {
            background-color: colors.$yellow-main;
          }
        }
        margin-bottom: 20px;
      }
      p {
        color: white;
        font-size: 2rem;
        text-align: center;
      }
    }
  }

  .footer-copyright {
    background-color: colors.$yellow-main;
    padding: 30px;
    font-size: 2rem;
  }
}

//Responsive
@media screen and (max-width: responsive.$small) {
  .footer {
    .footer-content-row {
      flex-direction: column;
      align-items: center;
      .logo-wrapper {
        width: 50%;
        order: 1;
        margin-bottom: 60px;
        margin-left: 0px;
      }

      .delivery-info {
        width: 80%;
        order: 2;

        // align-self: flex-start;
        margin-bottom: 30px;
        .glovo-wrapper {
          width: 40%;
          margin: 0 auto;
          // margin: 0px;
          // margin-right: auto;
        }
      }

      .social-network-container {
        width: 90%;
        // align-self: flex-end;
        .social-network-images {
          width: 50%;
          margin: 0 auto;
          margin-left: auto;
          margin-top: 40px;
        }
        p {
          margin-top: 0px;
          text-align: right;
        }
      }
    }
    //copyright
    .footer-copyright {
      font-size: 1.5rem;
    }
  }
}
